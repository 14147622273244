import { onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';
import authService from '@/api/auth';
import { useAuth } from '@/composables/useAuth';
import config from '@/config';
import { useAuthStore } from '@/stores/auth/auth';
import { checkRwcInterval } from '@/utils/constans';
/**
 * Hook for handling login page functionality.
 * Implements onMountedHooks logic for login page.
 * @returns Login page state and methods.
 */
export const useLoginPage = () => {
    const authStore = useAuthStore();
    const route = useRoute();
    const { getAuthProviders, authorizeRedirect } = useAuth();
    const accountData = {
        accountName: typeof route.query.accountName === 'string' ? route.query.accountName : undefined,
        accountId: typeof route.query.accountId === 'string' ? route.query.accountId : undefined,
    };
    const externalProviders = getAuthProviders();
    const isExpired = ref(false);
    const googleSSOLoading = ref(false);
    const closeExpiredNotification = () => {
        isExpired.value = false;
    };
    const loginIframeLogic = async ({ data }, { keepLoggedIn, accountData, }) => {
        if (data.type === 'SSO_SUCCESS_LOGIN') {
            if (data.user.tokenType === 'multi-user') {
                googleSSOLoading.value = true;
                await authService.saveMultiUser(data.user, keepLoggedIn.value);
                sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem('keepLoggedIn', `${keepLoggedIn.value}`);
                try {
                    await authStore.validateMultiUser();
                    await authStore.authorizeMult({
                        keepLoggedIn: keepLoggedIn.value,
                        accountData: accountData,
                    });
                }
                catch (e) {
                    console.warn(e);
                    googleSSOLoading.value = false;
                    authStore.cancelTwoFactorCheck();
                    throw e;
                }
            }
            else {
                authService.saveUser({
                    user: data.user,
                    long: keepLoggedIn.value,
                });
                authStore.sucessAuth(data.user);
            }
            authStore.cancelTwoFactorCheck();
        }
        if (data.type === 'SSO_TWO_FACTOR_CHECK') {
            // If error is present its being thrown inside of authStore.ssoLoginMFA
            authStore.setSSOTwoFactor(data);
        }
    };
    onBeforeMount(() => {
        if (route.query.sso) {
            authStore.setIsSSO();
        }
        if (authStore.authorized) {
            // validating user token each time in case token is expired or not valid
            return authService.validateUser(authStore.allowGuest)
                .then(async () => {
                if (authStore.isSSO && (accountData.accountId || accountData.accountName)) {
                    return authStore.authorizeMult({
                        keepLoggedIn: (sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('keepLoggedIn')) === 'true',
                        accountData,
                        source: 'cookie',
                    });
                }
                authorizeRedirect('cookie');
            })
                .catch(authStore.signOut);
        }
        if (config.HELPER_BOT_ENABLED) {
            // Hide chat on login page.
            const interval = setInterval(() => {
                const chat = document.querySelector('.or-rwc');
                if (chat) {
                    chat.style.display = 'none';
                    clearInterval(interval);
                }
            }, checkRwcInterval);
        }
    });
    return {
        loginIframeLogic,
        googleSSOLoading,
        externalProviders,
        accountData,
        isExpired,
        closeExpiredNotification,
    };
};
